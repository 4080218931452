import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {fadeIn, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger} from '../../../animations';
import * as PrismicDOM from 'prismic-dom';

@Component({
    selector: 'app-biography',
    templateUrl: './biography.component.html',
    styleUrls: ['./biography.component.scss'],
    animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]
})
export class BiographyComponent implements OnInit {
    about_text;
    intro_text;
    about;

    constructor(
        private activeRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.about = this.activeRoute.snapshot.data['about'].data;
        if (this.about.sub.length) {
            this.about.sub.forEach(sub => {
                if (sub.sub_text && sub.type !== 'preformatted') {
                    sub.text = PrismicDOM.RichText.asHtml(sub.sub_text, this.linkResolver);
                } else if (sub.type === 'preformatted') {
                    sub.text = sub.sub_text.text;
                }
            });
        }
        console.log(this.about);
        // this.about_text = PrismicDOM.RichText.asHtml(this.about.about, this.linkResolver);
        // this.intro_text = PrismicDOM.RichText.asHtml(this.about.intro[0].intro_text, this.linkResolver);
    }


    linkResolver(doc) {
        // Pretty URLs for known types
        if (doc.type === 'blog') return '/post/' + doc.uid;
        if (doc.type === 'page') return '/' + doc.uid;
        // Fallback for other types, in case new custom types get created
        return '/doc/' + doc.id;
    };

}
