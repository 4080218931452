import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NavbarService {

  private navbarSource: BehaviorSubject<object> = new BehaviorSubject({});
  public events = this.navbarSource.asObservable();

  constructor() {
    this.navbarSource.next({visible: true});
  }

  hide() {
    this.navbarSource.next({visible: false});
  }

  hideBackground() {
    this.navbarSource.next({background: false});
  }

  altColor() {
    this.navbarSource.next({altColor: true});
  }

  hideAltColor() {
    this.navbarSource.next({altColor: false});
  }

  scrollChange() {
    this.navbarSource.next({scrollChange: true});
  }

  noScrollChange() {
      this.navbarSource.next({scrollChange: false});
  }

  toggleAlwaysVisible(status) {
      this.navbarSource.next({alwaysVisible: status});
  }

  showBackground() {
    this.navbarSource.next({background: true});
  }

  show() {
    this.navbarSource.next({visible: true});
  }

}

