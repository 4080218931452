import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PostResolverService} from './services/post-resolver.service';
import {AboutResolverService} from './services/about-resolver.service';
import {HomeComponent} from './pages/home/home.component';
import {BiographyComponent} from './pages/biography/biography.component';
import {PostDetailComponent} from './pages/post-detail/post-detail.component';
import {WorksComponent} from './pages/works/works.component';
import {HomeResolverService} from './services/home-resolver.service';
import {ProjectResolverService} from './services/project-resolver.service';
import {PhotographyResolverService} from './services/photography-resolver.service';
import {WorkDetailComponent} from './pages/projects/work-detail/work-detail.component';
import {PhotographyDetailComponent} from './pages/projects/photography-detail/photography-detail.component';
import {PostComponent} from './pages/posts/post/post.component';


const homeRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        resolve: {
            home: HomeResolverService
        },
        children: [
        ]
    },
    {
        path: 'projects/photography/:url',
        component: PhotographyDetailComponent,
        resolve: {
            photography: PhotographyResolverService
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'posts/:url',
        component: PostComponent,
        resolve: {
            content: PostResolverService
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'projects/work/:url',
        component: WorkDetailComponent,
        resolve: {
            work: ProjectResolverService
        },
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'about',
        component: BiographyComponent,
        resolve: {
            about: AboutResolverService
        }
    },
    {
        path: 'works',
        component: WorksComponent
    },
    {path: '**', redirectTo: '/'},

];

@NgModule({
    imports: [RouterModule.forChild(homeRoutes)],
    exports: [RouterModule],
})

export class HomeRouting { }
