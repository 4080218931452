import { Injectable } from '@angular/core';
import * as Prismic from 'prismic-javascript';
import {environment} from '../../../environments/environment';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class HomeResolverService implements Resolve<any>{
    apiEndpoint = environment.prismic.endPoint;
    token = environment.prismic.token;

    constructor() { }

    resolve(route: ActivatedRouteSnapshot) {
        return Prismic.getApi(this.apiEndpoint, {accessToken: this.token}).then(function(api) {
            // @ts-ignore
            return api.getSingle('homepage',
                {'fetchLinks': ['post.preview_title', 'post.header_image', 'photography.preview_title', 'photography.header_image', 'work.preview_title', 'work.header_image']});
        }).then(res => res);
    }
}
