import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import * as Prismic from 'prismic-javascript';
import * as PrismicDOM from 'prismic-dom';

@Injectable({
    providedIn: 'root'
})

export class PostResolverService implements Resolve<any> {
    apiEndpoint = environment.prismic.endPoint;
    token = environment.prismic.token;

    constructor() { }

    async resolve(route: ActivatedRouteSnapshot) {
        const api = await Prismic.getApi(this.apiEndpoint, { accessToken: this.token });
        const res = await api.getByUID('post', route.paramMap.get('url'),
            {'fetchLinks': ['post.preview_title', 'post.header_image', 'author.name', 'author.profile_image']});
        if (res.data.body) {
            res.data.body.forEach(item => {
                switch (item.slice_type) {
                    case 'text':
                        item.text = PrismicDOM.RichText.asHtml(item.primary.text, this.linkResolver);
                        item.type = 'text';
                        break;
                }
            });
        }
        return res;
    }

    linkResolver(doc) {
        // Pretty URLs for known types
        if (doc.type === 'blog') { return '/post/' + doc.uid; }
        if (doc.type === 'page') { return '/' + doc.uid; }
        // Fallback for other types, in case new custom types get created
        return '/doc/' + doc.id;
    }
}


