import {trigger, state, animate, style, transition, query, stagger, keyframes} from '@angular/animations';

function slideToRight() {
  return trigger('slideToRight', [
    state('void', style({position:'fixed', width:'100%'}) ),
    state('*', style({position:'fixed', width:'100%'}) ),
    transition(':enter', [
      style({transform: 'translateX(-100%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateX(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateX(100%)'}))
    ])
  ]);
}

function slideToLeft() {
  return trigger('slideToLeft', [
    state('void', style({width:'100%',position:'absolute', display: 'flex', height:'100vh'}) ),
    state('*', style({width:'100%',position:'absolute', display: 'flex', height:'100vh'}) ),
    transition(':enter', [
      style({transform: 'translateX(100%)', opacity: 0}),
      animate('0.8s ease', style({transform: 'translateX(0%)', opacity: 1}))
    ]),
    transition(':leave', [
      style({transform: 'translateX(0%)', opacity: 1}),
      animate('0.8s ease', style({transform: 'translateX(-100%)', opacity: 0}))
    ])
  ]);
}

function slideToBottom() {
  return trigger('slideToBottom', [
    state('void', style({position:'fixed', width:'100%', height:'100%'}) ),
    state('*', style({position:'fixed', width:'100%', height:'100%'}) ),
    transition(':enter', [
      style({transform: 'translateY(-100%)'}),
      animate('0.5s ease', style({transform: 'translateY(0%)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0%)'}),
      animate('0.5s ease-in-out', style({transform: 'translateY(100%)'}))
    ])
  ]);
}

function slideToTop() {
    return trigger('slideToTop', [
        transition(':enter', [
            style({opacity: '0'}),
            animate(`0.3s ease`, keyframes([
                style({opacity: 0, transform: 'translateY(20px)', offset: 0}),
                style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
            ]))
        ])
    ]);
}

function slideToTopDelay1() {
    return trigger('slideToTopDelay1', [
        transition(':enter', [
            style({opacity: '0'}),
            animate(`0.3s 100ms ease`, keyframes([
                style({opacity: 0, transform: 'translateY(20px)', offset: 0}),
                style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
            ]))
        ])
    ]);
}
function slideToTopDelay2() {
    return trigger('slideToTopDelay2', [
        transition(':enter', [
            style({opacity: '0'}),
            animate(`0.3s 200ms ease`, keyframes([
                style({opacity: 0, transform: 'translateY(20px)', offset: 0}),
                style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
            ]))
        ])
    ]);
}
function slideToTopDelay3() {
    return trigger('slideToTopDelay3', [
        transition(':enter', [
            style({opacity: '0'}),
            animate(`0.3s 300ms ease`, keyframes([
                style({opacity: 0, transform: 'translateY(20px)', offset: 0}),
                style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
            ]))
        ])
    ]);
}

function slideToTopStagger(delay: number) {
  return trigger('slideToTopStagger', [
    transition('* => *', [

      query(':enter', style({ opacity: 0 }), {optional: true}),

      query(':enter', stagger('150ms', [
        animate(`0.5s ${delay ? delay + 'ms' : ''} ease`, keyframes([
          style({opacity: 0, transform: 'translateY(20px)', offset: 0}),
          style({opacity: 1, transform: 'translateY(-10px)',     offset: 0.8}),
          style({opacity: 1, transform: 'translateY(0)',     offset: 1.0}),
        ]))]), {optional: true}),

      query(':leave', style({ opacity: 1 }), {optional: true}),

      query(':leave', stagger('150ms', [
        animate('0.2s ease', keyframes([
          style({opacity: 1, transform: 'translateY(0)', offset: 0}),
          style({opacity: 0, transform: 'translateY(60px)',     offset: 1.0}),
        ]))]), {optional: true})
    ])
  ]);
}

function slideToLeftStagger(delay: number) {
  return trigger('slideToLeftStagger', [
    transition('* => *', [

      query(':enter', style({ opacity: 0 }), {optional: true}),

      query(':enter', stagger('150ms', [
        animate(`0.5s ${delay ? delay + 'ms' : ''} ease`, keyframes([
          style({opacity: 0, transform: 'translateX(20px)', offset: 0}),
          style({opacity: 1, transform: 'translateX(-10px)',     offset: 0.8}),
          style({opacity: 1, transform: 'translateX(0)',     offset: 1.0}),
        ]))]), {optional: true}),

      query(':leave', style({ opacity: 1 }), {optional: true}),

      query(':leave', stagger('150ms', [
        animate('0.2s ease', keyframes([
          style({opacity: 1, transform: 'translateX(0)', offset: 0}),
          style({opacity: 0, transform: 'translateX(60px)',     offset: 1.0}),
        ]))]), {optional: true})
    ])
  ]);
}


function fadeInOut() {
  return trigger('fadeInOut', [
    transition(':enter', [
      style({opacity: '0'}),
      animate('0.5s ease-in-out', style({opacity: '1'}))
    ]),
    transition(':leave', [
      style({opacity: '1'}),
      animate('0.2s ease-in-out', style({opacity: '0'}))
    ])
  ]);
}

function fadeIn() {
  return trigger('fadeIn', [
    transition(':enter', [
      style({opacity: '0'}),
      animate('0.5s ease-in-out', style({opacity: '1'}))
    ])
  ]);
}

export {fadeInOut, fadeIn, slideToRight,  slideToLeft,  slideToBottom, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger, slideToLeftStagger};

