import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {fadeIn, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger} from '../../../../animations';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]
})

export class PostComponent implements OnInit {
  content;
  navigationSubscription;
  description;

  constructor(private activeRoute: ActivatedRoute,
              private router: Router) {

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseData();
      }
    });
  }

  ngOnInit() {
    if (this.content === undefined) {
      this.router.navigate(['/']);
    }
  }




  initialiseData() {
    this.content = this.activeRoute.snapshot.data['content'].data;
    console.log(this.content);
  }
}
