import {Component, OnInit} from '@angular/core';
import {fadeIn, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger} from '../../../../animations';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as PrismicDOM from 'prismic-dom';

@Component({
    selector: 'app-work-detail',
    templateUrl: './work-detail.component.html',
    styleUrls: ['./work-detail.component.scss'],
    animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]
})
export class WorkDetailComponent implements OnInit {
    work;
    intro;
    navigationSubscription;
    description;

    constructor(private activeRoute: ActivatedRoute,
                private router: Router) {

        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.initialiseData();
            }
        });
    }

    ngOnInit() {
        if (this.work === undefined) {
            this.router.navigate(['/']);
        }
    }


    linkResolver(doc) {
        // Pretty URLs for known types
        if (doc.type === 'blog') return "/post/" + doc.uid;
        if (doc.type === 'page') return "/" + doc.uid;
        // Fallback for other types, in case new custom types get created
        return "/doc/" + doc.id;
    };

    initialiseData() {
        this.work = this.activeRoute.snapshot.data['work'].data;
        console.log(this.work);
        this.intro = PrismicDOM.RichText.asHtml(this.work.intro, this.linkResolver);
        this.description = PrismicDOM.RichText.asHtml(this.work.description, this.linkResolver);
        console.log(this.description);

        if(this.work.sub_details) {
            this.work.sub_details.forEach(detail => {
                if(detail.description1) {
                    detail.description1.text = PrismicDOM.RichText.asHtml(detail.description1, this.linkResolver);
                }
            })
        }
    }
}
