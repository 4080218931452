import { Component, OnInit } from '@angular/core';
import {ResolveEnd, ResolveStart, Router} from '@angular/router';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  show = false;
  constructor(private router: Router) {
      this.router.events.subscribe(event => {
          if (event instanceof ResolveEnd ) {
              setTimeout(()=>{    //<<<---    using ()=> syntax
                  this.show = false;
              }, 0);
          }
      });
  }

  ngOnInit() {
  }

}
