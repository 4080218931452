import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, ResolveEnd, Router} from '@angular/router';
import {NavbarService} from './navbar.service';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    menuState = false;
    inScreen;
    scrollChange;
    alwaysVisible;
    altColor;
    inScreenBackground;
    hideBackground;

    constructor(private router: Router,
                private navbar: NavbarService) {
        this.router.events.subscribe((e) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationStart && this.menuState) {
                this.menuState = false;
            }
            if (e instanceof ResolveEnd) {
                this.inScreenBackground = false;
            }
        });

        this.navbar.events.subscribe(ev => {
            // @ts-ignore
            ev.hasOwnProperty('background') ? this.hideBackground = ev.background : null;

            // @ts-ignore
            ev.hasOwnProperty('altColor') ? this.altColor = ev.altColor : null;

            // @ts-ignore
            ev.hasOwnProperty('scrollChange') ? this.scrollChange = ev.scrollChange : null;

            // @ts-ignore
            ev.hasOwnProperty('alwaysVisible') ? this.alwaysVisible = ev.alwaysVisible : null;
        });

    }


    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const component = document.getElementById('start-container');
        if (component) {
            const componentPosition = component.offsetTop;
            let scrollPositionBackground = window.pageYOffset;
            let scrollPositionText = window.pageYOffset + 45;


            this.inScreenBackground = scrollPositionBackground >= componentPosition && this.hideBackground;
            this.inScreen = scrollPositionText >= componentPosition;
        } else if (!this.scrollChange && this.alwaysVisible) {
            this.inScreenBackground = true;
            this.inScreen = false;
        } else {
            this.inScreenBackground = false;
            this.inScreen = false;
        }
    }

    scrollToTop() {
        (function smoothscroll() {
            var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / 5));
            }
        })();
    }

    ngOnInit() {

    }

    openMenu() {
        this.menuState = !this.menuState;
    }
}
