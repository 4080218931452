import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MdToHtmlPipe} from './services/md-to-html.pipe';
import { HtmlSerializerPipe } from './pipes/html-serializer.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [MdToHtmlPipe, HtmlSerializerPipe],
    exports: [MdToHtmlPipe, HtmlSerializerPipe]
})
export class SharedModule {
}
