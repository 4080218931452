import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import * as Prismic from 'prismic-javascript';

@Injectable({
  providedIn: 'root'
})
export class PrismicService {
    apiEndpoint = environment.prismic.endPoint;
    token = environment.prismic.token;

    constructor() {}


    getPosts(){
        return Prismic.getApi(this.apiEndpoint, {accessToken: this.token}).then(function(api) {
            // @ts-ignore
            return api.query(""); // An empty query will return all the documents
        }).then(function(response) {
            console.log("Documents: ", response.results);
            return response;
        }, function(err) {
            console.log("Something went wrong: ", err);
        });
    }
}
