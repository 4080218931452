// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    contentful: {
        spaceId: 'vbre71lzon5g',
        token: 'b4acdf1309513e23dbe513e91cf146ded0e524ebb2a19501ae7653916d2e1068'
    },
    prismic: {
        cliendId: 'W2-VSyYAABxIHi64',
        secret: 'f9cea71438fc490a560d96ca644e041d',
        token: 'MC5XMi1WU3lZQUFCeElIaTY1.77-977-977-977-977-977-9Ne-_ve-_ve-_ve-_ve-_vXXvv70I77-9Fu-_ve-_ve-_ve-_vVxN77-9Nu-_ve-_vQlg77-9aO-_vQ',
        endPoint: 'https://bytesbase.cdn.prismic.io/api/v2'
    }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
