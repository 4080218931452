import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as PrismicDOM from 'prismic-dom';
import {fadeIn, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger} from '../../../../animations';

@Component({
    selector: 'app-photography-detail',
    templateUrl: './photography-detail.component.html',
    styleUrls: ['./photography-detail.component.scss'],
    animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]

})
export class PhotographyDetailComponent implements OnInit {
    content;
    navigationSubscription;
    description;

    constructor(private activeRoute: ActivatedRoute,
                private router: Router) {

        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.initialiseData();
            }
        });
    }

    ngOnInit() {
        if (this.content === undefined) {
            this.router.navigate(['/']);
        }
    }


    linkResolver(doc) {
        // Pretty URLs for known types
        if (doc.type === 'blog') return '/post/' + doc.uid;
        if (doc.type === 'page') return '/' + doc.uid;
        // Fallback for other types, in case new custom types get created
        return '/doc/' + doc.id;
    };

    initialiseData() {
        this.content = this.activeRoute.snapshot.data['photography'].data;
        console.log(this.content);
        this.description = PrismicDOM.RichText.asHtml(this.content.description, this.linkResolver);
    }
}
