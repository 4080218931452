import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ContentfulService} from './services/contentful.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AngularSvgIconModule } from 'angular-svg-icon';

import {HomeModule} from './home/home.module';
import {RoutingModule} from './app.routing';
import {NavbarComponent} from './navbar/navbar.component';
import {NavbarService} from './navbar/navbar.service';
import {SharedModule} from './shared/shared.module';
import {PrismicService} from './services/prismic.service';
import {CoreModule} from './core/core.module';


@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AngularSvgIconModule,
        BrowserAnimationsModule,
        RoutingModule,
        HomeModule,
        SharedModule,
        CoreModule
    ],
    providers: [
        ContentfulService,
        NavbarService,
        PrismicService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
