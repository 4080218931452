import {Component, HostBinding} from '@angular/core';
import {fadeIn} from './animations';
import {NavigationEnd, ResolveEnd, Router} from '@angular/router';
import {NavbarService} from './navbar/navbar.service';

declare let ga: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeIn()]
})
export class AppComponent {
    @HostBinding('@fadeIn')
    showMenu: object;

    constructor(private navbar: NavbarService,
                private router: Router) {
        let w: number = screen.width;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                switch (event.url) {
                    case '/' :
                        this.navbar.hideBackground();
                        this.navbar.noScrollChange();
                        this.navbar.toggleAlwaysVisible(false);
                        this.navbar.altColor();
                        break;
                    case '/about' :
                        this.navbar.showBackground();
                        this.navbar.toggleAlwaysVisible(true);
                        this.navbar.noScrollChange();
                        break;
                    default:
                        this.navbar.scrollChange();
                        this.navbar.show();
                        this.navbar.showBackground();
                        this.navbar.hideAltColor();
                        this.navbar.toggleAlwaysVisible(false);
                        break;
                }
            }
            if (event instanceof ResolveEnd) {
                window.scrollTo(0, 0);
            }
        });

    }


}
