import { Injectable } from '@angular/core';
import * as Prismic from 'prismic-javascript';
import {environment} from '../../../environments/environment';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class ProjectResolverService implements Resolve<any>{
    apiEndpoint = environment.prismic.endPoint;
    token = environment.prismic.token;

    constructor() { }

    resolve(route:ActivatedRouteSnapshot) {
        return Prismic.getApi(this.apiEndpoint, {accessToken: this.token}).then(function(api) {
            // @ts-ignore
            return api.getByUID('work', route.paramMap.get('url'))
        }).then(res => res);
    }
}
