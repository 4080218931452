import {Component, OnInit} from '@angular/core';
import {ContentfulService} from '../../../services/contentful.service';
import {Entry} from 'contentful';
import {Router} from '@angular/router';
import {fadeIn, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger} from '../../../animations';

@Component({
    selector: 'app-works',
    templateUrl: './works.component.html',
    styleUrls: ['./works.component.scss'],
    animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]
})
export class WorksComponent implements OnInit {

    posts: Entry<any> [] = [];
    activePreview: string = '';

    constructor(private router: Router,
                private contentfulService: ContentfulService) {
    }

    ngOnInit() {
        this.contentfulService.getPosts()
            .then(posts => {
                this.posts = posts;
            });
    }

    setActivePost(post) {
        let old = this.posts.find(post => post.fields.active === true);
        old ? old.fields.active = false : null;
        post.fields.active = true;
        this.activePreview = post.fields.headerImage.fields.file.url;
    }

    goToPost(postId) {
        if (postId) {
            this.router.navigate(['/posts', postId]);
        } else {
            let activePost = this.posts.find(post => post.fields.active === true);
            // this.router.navigate(['home/posts', activePost.fields.url]);
            this.router.navigate(['/posts', activePost.sys.id]);
        }
    }
}
