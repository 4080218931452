import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {
    fadeIn,
    slideToTop,
    slideToTopDelay1,
    slideToTopDelay2, slideToTopDelay3,
    slideToTopStagger
} from '../../../animations';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
    animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]
})
export class PostDetailComponent implements OnInit {
    post;
    navigationSubscription;

    constructor(private activeRoute: ActivatedRoute,
                private router: Router) {

        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.initialiseData();
            }
        });
    }

    ngOnInit() {
        this.initialiseData();

        if (this.post === undefined) {
            this.router.navigate(['/']);
        }
    }



    initialiseData() {
        this.post = this.activeRoute.snapshot.data['post'].data;
        console.log(this.post);
    }

}
