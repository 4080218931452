import {Injectable} from '@angular/core';
import {createClient, Entry} from 'contentful';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContentfulService {
    private client = createClient({
        space: environment.contentful.spaceId,
        accessToken: environment.contentful.token
    });

    constructor() {
    }

    getPosts(query?: object): Promise<Entry<any>[]> {
        return this.client.getEntries(Object.assign({
            content_type: 'photography'
        }, query))
            .then(res => res.items);
    }

    getPost(postURL): Promise<Entry<any>> {
        return this.client.getEntries(Object.assign({
            content_type: 'photography'
        }, {'fields.url': postURL}))
            .then(res => res.items[0]);
    }


    getAbout(): Promise<Entry<any>> {
        return this.client.getEntries(Object.assign({
            content_type: 'about'
        }, {'fields.title': 'Bytesbase'}))
            .then(res => res.items[0]);
    }

}
