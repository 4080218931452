import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeRouting} from './home.routing';
import {PostResolverService} from './services/post-resolver.service';
import {SharedModule} from '../shared/shared.module';
import { WorksComponent } from './pages/works/works.component';
import { BiographyComponent } from './pages/biography/biography.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import {PostDetailComponent} from './pages/post-detail/post-detail.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { WorkDetailComponent } from './pages/projects/work-detail/work-detail.component';
import { PhotographyDetailComponent } from './pages/projects/photography-detail/photography-detail.component';
import { PostComponent } from './pages/posts/post/post.component';


@NgModule({
    imports: [
        CommonModule,
        HomeRouting,
        SharedModule,
        LazyLoadImageModule
    ],
    declarations: [
        WorksComponent,
        BiographyComponent,
        ContactComponent,
        FooterComponent,
        HomeComponent,
        PostDetailComponent,
        WorkDetailComponent,
        PhotographyDetailComponent,
        PostComponent
    ],
    providers: [
        PostResolverService
    ]
})
export class HomeModule {
}
