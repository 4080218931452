import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Entry} from 'contentful';
import {ContentfulService} from '../../../services/contentful.service';
import {fadeIn, slideToTop, slideToTopDelay1, slideToTopDelay2, slideToTopDelay3, slideToTopStagger} from '../../../animations';
import {PrismicService} from '../../../services/prismic.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [slideToTopStagger(300), slideToTop(), slideToTopDelay1(), slideToTopDelay2(), slideToTopDelay3(), fadeIn()]

})
export class HomeComponent implements OnInit {

    home;
    activePost: any = '';

    constructor(private router: Router,
                private activeRoute: ActivatedRoute,
                private prismicService: PrismicService) {
    }

    ngOnInit() {
        this.home = this.activeRoute.snapshot.data['home'].data;

    }

    setActiveWork(work) {
        const old = this.home.works.find(work => work.work.active === true);
        old ? old.work.active = false : null;
        work.work.active = true;
        this.activePost = true;
    }

    removeActiveWork() {
        const old = this.home.works.find(work => work.work.active === true);
        old ? old.work.active = false : null;
        this.activePost = false;
    }
    setActivePost(post) {
        const old = this.home.posts.find(post => post.post.active === true);
        old ? old.post.active = false : null;
        post.post.active = true;
        this.activePost = true;
    }

    removeActivePost() {
        const old = this.home.posts.find(post => post.post.active === true);
        old ? old.post.active = false : null;
        this.activePost = false;
    }

    goToPost(item) {
        switch (item.post.type) {
            case 'post':
                this.router.navigate(['/posts/', item.post.uid]);
                break;
            case 'photography':
                this.router.navigate(['/projects/photography/', item.post.uid]);
                break;
        }
    }

    goToWork(work) {
        switch (work.work.type) {
            case 'work':
                this.router.navigate(['/projects/work/', work.work.uid]);
                break;
            case 'photography':
                this.router.navigate(['/projects/photography/', work.work.uid]);
                break;
        }
    }
}

